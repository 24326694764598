import React, { useState } from 'react';
import 'react-dates/initialize';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import Select, { OnChangeValue } from 'react-select';
import moment, { Moment } from 'moment';

import EntryCard from '../card';
import Entry from '../../../models/entry';

import 'react-dates/lib/css/_datepicker.css';
import './style.css';

interface EntryFilterOption {
  value: string;
  label: string;
  type: 'type' | 'meal_type';
}

export interface Props {
  entries: Entry[];
  initialStartDate: Moment | null;
  initialEndDate: Moment | null;
}

export interface State {
  startDate: Moment | null;
  endDate: Moment | null;
  focusedInput: 'startDate' | 'endDate' | null;
  selectedTypes: EntryFilterOption[];
}

const options: EntryFilterOption[] = [
  { value: 'breakfast', label: 'Breakfast', type: 'meal_type' },
  { value: 'lunch', label: 'Lunch', type: 'meal_type' },
  { value: 'dinner', label: 'Dinner', type: 'meal_type' },
  { value: 'snack', label: 'Snack', type: 'meal_type' },
  { value: 'activity', label: 'Activity', type: 'type' },
];

const colorStyles = {
  control: (styles: object) => ({
    ...styles,
    height: '48px',
    borderRadius: 0,
    borderColor: 'rgb(219, 219, 219)',
    minWidth: '288px',
  }),
};

export default function EntriesList(props: Props) {
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
    null,
  );
  const [startDate, setStartDate] = useState(props.initialStartDate);
  const [endDate, setEndDate] = useState(props.initialEndDate);
  const [selectedTypes, setSelectedTypes] = useState([] as EntryFilterOption[]);

  function datesChanged(arg: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) {
    setStartDate(arg.startDate ? arg.startDate.startOf('day') : null);
    setEndDate(arg.endDate ? arg.endDate.endOf('day') : null);
  }

  function typeFilterChanged(value: OnChangeValue<EntryFilterOption, true>) {
    const selectedTypes = (value ?? []) as EntryFilterOption[];
    setSelectedTypes(selectedTypes);
  }

  function isOutsideRangeFunc(date: Moment): boolean {
    if (props.initialStartDate && date.isBefore(props.initialStartDate)) {
      return true;
    }

    if (props.initialEndDate && date.isAfter(props.initialEndDate)) {
      return true;
    }

    return false;
  }

  const allowedTypes = selectedTypes.map((t) =>
    t.type === 'meal_type' ? 'meal' : t.value,
  );

  const allowedMealTypes = selectedTypes
    .filter((t) => t.type === 'meal_type')
    .map((t) => t.value);

  const items = props.entries
    .filter((e) => {
      if (e.type !== 'meal' && e.type !== 'activity') {
        return false;
      }

      if (allowedTypes.length > 0) {
        if (!allowedTypes.includes(e.type)) {
          return false;
        }

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        if (e.type === 'meal' && !allowedMealTypes.includes(e.meal!.type)) {
          return false;
        }
      }

      const entryDate = moment(e.date);

      if (startDate && entryDate.isBefore(startDate)) {
        return false;
      }

      if (endDate && entryDate.isAfter(endDate)) {
        return false;
      }

      return true;
    })
    .map((e) => {
      return <EntryCard entry={e} key={e.id} />;
    });

  return (
    <div className="container">
      <div className="row justify-content-center entry-list-header">
        <div className="col-auto entry-date-filter-container">
          <div className="d-flex flex-row">
            <span className="p-2 entry-filter-title date-title">Date</span>
            <div className="p-2">
              <DateRangePicker
                startDate={startDate}
                startDateId="startDate"
                endDate={endDate}
                endDateId="endDate"
                onDatesChange={datesChanged}
                focusedInput={focusedInput}
                onFocusChange={setFocusedInput}
                isOutsideRange={isOutsideRangeFunc}
              />
            </div>
          </div>
        </div>
        <div className="col-auto entry-type-filter-container">
          <div className="d-flex flex-row">
            <span className="p-2 entry-filter-title type-title">Type</span>
            <div className="p-2 flex-fill">
              <Select
                options={options}
                styles={colorStyles}
                isMulti={true}
                onChange={typeFilterChanged}
                value={selectedTypes}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-auto">
          <div className="entries-list">
            {items.length > 0 ? (
              items
            ) : (
              <div className="no-entries">No entries found</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
