import React, { ReactNode } from 'react';
import Entry, { MealEntryDetails } from '../../../models/entry';
import Moment from 'moment';
import { Carousel } from 'react-responsive-carousel';

import './style.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

export interface Props {
  entry: Entry;
}

export default function EntryCard(props: Props) {
  function mealEntryTypeImage(meal: MealEntryDetails): ReactNode {
    switch (meal.type) {
      case 'breakfast':
        return <img src="images/breakfast.svg" alt="breakfast" />;
      case 'lunch':
        return <img src="images/lunch.svg" alt="lunch" />;
      case 'dinner':
        return <img src="images/dinner.svg" alt="dinner" />;
      case 'snack':
        return <img src="images/snack.svg" alt="snack" />;
    }
  }

  function activityEntryTypeImage(): ReactNode {
    return <img src="images/activity.svg" alt="activity" />;
  }

  function entryTypeImage(entry: Entry): ReactNode {
    switch (entry.type) {
      case 'meal':
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return mealEntryTypeImage(entry.meal!);
      case 'activity':
        return activityEntryTypeImage();
      default:
        return;
    }
  }

  function mealEntryTitle(meal: MealEntryDetails): string {
    switch (meal.type) {
      case 'breakfast':
        return 'Breakfast';
      case 'lunch':
        return 'Lunch';
      case 'dinner':
        return 'Dinner';
      case 'snack':
        return 'Snack';
    }
  }

  function entryTitle(entry: Entry): string {
    switch (entry.type) {
      case 'activity':
        return 'Activity';
      case 'measurement':
        return 'Measurement';
      case 'meal':
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return mealEntryTitle(entry.meal!);
    }
  }

  function entryTime(entry: Entry): string {
    return Moment(entry.date).calendar({
      sameElse: 'L LT',
    });
  }

  const entry = props.entry;
  const { description, meal, images, tags } = entry;
  const estimatedCalories = meal?.estimatedCalories;
  const mealFeelings = meal?.feelings;
  const caloriesText = estimatedCalories
    ? `${estimatedCalories.from} - ${estimatedCalories.to} kcal`
    : undefined;

  return (
    <div className="container entry-card">
      <div className="row entry-card-header">
        <div className="entry-type">{entryTypeImage(entry)}</div>
        <div className="col">
          <div className="entry-title">{entryTitle(entry)}</div>
          <div className="entry-time">{entryTime(entry)}</div>
        </div>
        {mealFeelings && (
          <div className="indicators">
            <div className="indicator">
              <span>H</span>
              <div className="progress-bar">
                <div
                  className="progress"
                  style={{ width: `${mealFeelings.hungerBefore}%` }}
                ></div>
              </div>
            </div>
            <div className="indicator">
              <span>F</span>
              <div className="progress-bar">
                <div
                  className="progress"
                  style={{ width: `${mealFeelings.fullnessAfter}%` }}
                ></div>
              </div>
            </div>
          </div>
        )}
      </div>
      {images && images.length > 0 && (
        <>
          <div className="row image-container">
            {caloriesText && (
              <div className="entry-calories-container">{caloriesText}</div>
            )}
            {images.length === 1 ? (
              <img
                src={images[0].imageURL}
                className="entry-image"
                alt="entry"
              />
            ) : (
              <Carousel showThumbs={false}>
                {images.map((image) => (
                  <img
                    src={image.imageURL}
                    className="entry-image"
                    alt="entry"
                  />
                ))}
              </Carousel>
            )}
            {tags && tags.length > 0 && (
              <div className="tags-container">
                {tags.map((tag, index) => (
                  <span key={index} className="tag">
                    {tag}
                  </span>
                ))}
              </div>
            )}
          </div>
          {description && (
            <div className="entry-card-description-container">
              <span className="entry-card-description">{description}</span>
            </div>
          )}
        </>
      )}
      {(!images || images.length === 0) && (
        <div className="row">
          <div className="col entry-text-only">{description}</div>
        </div>
      )}
    </div>
  );
}
