import React from 'react';
import './style.css';

export function Loader() {
  return (
    <div className="full-screen-loader">
      <div className="lds-spinner" />
    </div>
  );
}
