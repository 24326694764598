import React, { useState, useEffect } from 'react';
import moment, { Moment } from 'moment';
import EntriesList from './components/entries/list';
import { Loader } from './components/loader';
import { ApiURL } from './constants';
import Entry from './models/entry';
import './App.css';

export interface Props {
  slug: string;
}

interface SharedLink {
  id: string;
  userId: string;
  slug: string;
  createdAt: string;
  expiresAt?: string;
  startDate?: string;
  endDate?: string;
}

export default function App(props: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const [entries, setEntries] = useState<Entry[]>([]);
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [endDate, setEndDate] = useState<Moment | null>(null);
  const [error, setError] = useState<string | null>(null);

  async function doLoadData(props: Props) {
    let response = await fetch(`${ApiURL}/v2/share/${props.slug}`);

    if (!response.ok) {
      throw new Error('Shared link expired or not valid');
    }

    const sharedLink = (await response.json()) as SharedLink;

    response = await fetch(`${ApiURL}/v2/share/${props.slug}/entries`);

    if (!response.ok) {
      throw new Error('Shared link expired or not valid');
    }

    const newEntries = (await response.json()) as Entry[];

    setIsLoading(false);
    setError(null);
    setEntries(newEntries);
    setStartDate(
      sharedLink.startDate ? moment(sharedLink.startDate).startOf('day') : null,
    );
    setEndDate(
      sharedLink.endDate ? moment(sharedLink.endDate).endOf('day') : null,
    );
  }
  async function loadData(props: Props) {
    try {
      await doLoadData(props);
    } catch (e: unknown) {
      setError((e as Error).message ?? e);
    }
  }

  useEffect(() => {
    loadData(props);
  }, []);

  return (
    <div className="App">
      {error ? (
        <div className="load-error">{`${error}`}</div>
      ) : (
        <div>
          {isLoading ? (
            <Loader />
          ) : (
            <EntriesList
              entries={entries}
              initialStartDate={startDate}
              initialEndDate={endDate}
            />
          )}
        </div>
      )}
    </div>
  );
}
